import React from 'react'
import { SvgIcon } from '@material-ui/core'

function Portal(props) {
  return (
    <SvgIcon {...props} width="20" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M8.71429 1L10 1.64286V2.92857L8.71429 3.57143L10.6429 4.85714H11.2857V3.57143H13.2143V4.85714L13.8571 5.5V6.78571L13.2143 7.42857H11.9286L9.35714 6.14286L8.07143 6.78571H6.14286L5.5 9.35714L7.42857 11.2857L8.71429 11.9286V13.8571L9.35714 14.5V15.7857L10 17.0714H11.2857L12.5714 15.1429L13.2143 13.8571V12.5714L15.1429 10L14.5 8.71429H16.4286V7.42857H19M10 19C5.02944 19 1 14.9706 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10C19 14.9706 14.9706 19 10 19Z"
        stroke="#AAAAAA"
        strokeWidth="1.8"
        strokeLinejoin="round"
        style={{ color: 'transparent' }}
      />
    </SvgIcon>
  )
}

export default Portal
